<template>
  <site-layout class="login" :background-image="backgroundImage">
    <div class="content">
      <el-row class="header">
        <el-col :span="12" v-for="(tab, index) in tabs" :key="index">
          <h4 @click="handleTab(index)">
            <span :class="{active: activeIndex === index}">{{tab.name}}</span>
          </h4>
        </el-col>
      </el-row>

      <div class="loginForm form" v-if="tabType === 0">
        <el-form ref="loginForm" :model="loginForm" :rules="rules">
          <el-form-item prop="loginName">
            <el-input v-model="loginForm.loginName" prefix-icon="el-icon-user" placeholder="请输入账号"></el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input show-password v-model="loginForm.password" prefix-icon="el-icon-key"
                      placeholder="请输入密码"></el-input>
          </el-form-item>

          <el-form-item style="margin-bottom: 5px;">
            <el-row>
              <el-col :span="12">
                <el-checkbox border v-model="loginForm.checked">记住账号</el-checkbox>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button type="text">忘记密码</el-button>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item>
            <el-button class="btn-login" @click="onLogin2">登 录</el-button>
          </el-form-item>

          <el-form-item class="text-center">
            <el-button type="text" @click="toRegister">立即注册</el-button>
          </el-form-item>
        </el-form>

      </div>

      <!-- 手机号登录 -->
      <div class="loginForm form" v-if="tabType === 1">
        <el-form ref="telLoginForm" :model="loginForm">
          <el-form-item>
            <el-input v-model="loginForm.telphone" prefix-icon="el-icon-mobile-phone"
                      placeholder="请输入手机号码"></el-input>
          </el-form-item>

          <el-form-item>
            <el-row :gutter="5">
              <el-col :span="16">
                <el-input v-model="loginForm.smsCaptcha" prefix-icon="el-icon-message"
                          placeholder="请输入验证码"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button style="width: 100%;" plain @click="sendSMS" :disabled="btnSMSDisabled">{{ btnSMS }}
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item>
            <el-button class="btn-login" @click="onLogin2">1登 录</el-button>
          <!--  <el-button class="btn-login" @click="onLogin">登 录</el-button> -->
          </el-form-item>

          <el-form-item>
            <el-row>
              <el-col :span="12">
                <el-button type="text" @click="toRegister">立即注册</el-button>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button type="text">忘记密码</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>

      </div>
    </div>
  </site-layout>
</template>

<script>
		import axios from "axios";
  import SiteLayout from "../layout/SiteLayout";
  import {checkLogin} from '../api/LoginApi'
  import {getToken, setSessionUser} from "../utils/Storage";

  export default {
    name: "Login",
    components: {SiteLayout},
    data() {
      return {
        backgroundImage: require('../assets/images/bg-login.png'),
        searchActiveIndex: 0,
        tabType: 0,
        tabs: [
          {name: "账号密码登录", type: 0}, {name: "手机号码登录", type: 1}
        ],
        loginForm: {},
        btnSMS: '发送验证码',
        btnSMSDisabled: false,

        rules: {
          loginName: [
            {required: true, message: "账号不能为空", trigger: 'blur'}
          ],
          password: [
            {required: true, message: "密码不能为空", trigger: 'blur'},
          ],
        }
      }
    },
    methods: {
	 onLogin2(){ 
		 
			const params = new URLSearchParams(); 
			params.append('name', this.loginForm.loginName);
			params.append('pwd', this.loginForm.password);
			console.log(params);
			axios.post(this.apiUrl+'/api/login/index',params)
			.then((response) => {  
						
					    if(response.data.code == 3){ 
							this.$message.error('账户密码错误');
						}else{
							sessionStorage.setItem("uid", response.data.data.id);
							sessionStorage.setItem("uname",response.data.data.name);
							sessionStorage.setItem("type",response.data.data.type);
							
							 this.$router.replace('/home')
						}
							  
							// this.$alert('操作成功!', this.alert_mes, {
							// 	confirmButtonText: '确定',
							// 	callback: action => {
							// 	  this.$router.replace('/home')
							// 	}
							// });
						  
						  
			  })
			
			
			  .catch(function(error) {
			    console.log(error);
			  }); 
	  },
      // 处理tab选中的类型
      handleTab(index) {
        this.activeIndex = index;
        this.tabType = this.tabs[index].type
      },

      /**
       * 打开注册页面
       */
      toRegister() {
        this.$router.push('/register')
      },

      /**
       * 发送短信验证
       */
      sendSMS() {
        this.btnCountDown();
      },

      /**
       * 执行短信验证码的倒计时
       */
      btnCountDown() {
        this.btnSMSDisabled = true;
        let lastSecond = 120;
        let suffixStr = "秒后重发";
        this.btnSMS = lastSecond + suffixStr;
        let clearTimer = setInterval(() => {
          lastSecond--;
          if (lastSecond <= 0) {
            console.log(lastSecond, clearTimer, this);
            this.btnSMSDisabled = false;
            this.btnSMS = '发送验证码';
            clearInterval(clearTimer);
          } else {
            this.btnSMS = lastSecond + suffixStr;
          }
        }, 1000)
      },

      /**
       * 点击了登录的操作按钮
       */
      onLogin() {
        this.$refs['loginForm'].validate((valid) => {
          if (valid) {
            checkLogin(this.loginForm).then(res => {
              if (getToken()) {
                setSessionUser(res)
                if (res.status !== 1) {
                  this.$router.replace({name: "注册账号"})
                } else {
                  this.$router.replace({name: "网站首页"})
                }
              }
            })
          }
        })
      }

    }
  }
</script>

<style scoped>
  .content {
    width: 400px;
    height: 320px;
    position: relative;
    left: calc(1200px - 450px);
    top: 120px;
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 10px;
    padding: 25px;
  }

  .header {
    padding: 0px 50px;
  }

  .header h4 {
    text-align: center;
    cursor: pointer;
  }

  .header .active {
    display: block;
    width: max-content;
    padding-bottom: 10px;
    border-bottom: 3px solid #3CD0DC;
    color: #3CD0DC;
    margin: auto;
  }

  .form {
    padding: 0px 50px;
  }

  .el-checkbox.is-bordered {
    padding-left: 0px !important;
    border: unset;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .btn-login {
    width: 100%;
  }

  .el-button--text {
    color: #3BD1DC !important;
  }
</style>
