import axios from 'axios'
import store from "../store";
import {clearSessionUser, getToken, setToken} from "../utils/Storage";
import {Message} from "element-ui";

/**
 * 给axios设置访问的跟路径信息
 */
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = ''
}

/**
 * 自定义请求时带的数据信息
 */
axios.interceptors.request.use(config => {
  if(getToken()){
    config.headers.token = getToken()
  }
  return config
}, error => Promise.reject(error))

/**
 * 针对axios返回数据做的封装处理
 */
// axios.interceptors.response.use(response => {
//   const res = response.data;
//   // console.log(res)
//   if (res.code == 200) {
//     if (res.token) {
//       setToken(res.token)
//     }
//     return res.data
//   } else if (res.code == 500) {
//     const msg = res.message || '服务异常';
//     Message.error(msg);
//     return Promise.reject(res);
//   } else if (res.code == 501) {
//     const msg = res.message || '服务异常';
//     // store.dispatch('logout')
//     Message.error(msg);
//     return Promise.reject(response);
//   } else if (res.code == 502) {
//     const msg = res.message || '服务异常';
//     clearSessionUser();
//     window.location.reload(true);
//     // store.dispatch('logout')
//     Message.error(msg);
//     return Promise.reject(response);
//   }
// }, error => {
//   console.log(error.response)
//   // message.error('服务异常');
//   return Promise.reject(error)
// })


export default axios
