<template>
  <div class="m-button">
    <i class="el-icon-star-on" style="color: #F8B953; font-size: 20px">

    </i>
    <span style="color: #000;">收藏本站</span>
  </div>
</template>

<script>
  export default {
    name: "MButton"
  }
</script>

<style scoped>
.m-button{
  display:inline;
}
</style>
