<template>
	<el-container class="site-layout">
		<el-header :height="top ? '130px' : '100px'" style="108px;">
			<div class="top" v-if="top">
				<el-row>
					<el-col :span="18">
						<el-button type="text" :icon="collect? 'el-icon-star-on' :'el-icon-star-off'"
							@click="collect = !collect">
							收藏本站
						</el-button>
						<el-button type="text" icon="el-icon-mobile-phone">手机app</el-button>
						<el-button type="text" icon="el-icon-chat-dot-round">公众号</el-button>
					</el-col>

					<el-col :span="6" class="text-right" v-if="!users.id">
						<!--            <el-breadcrumb separator="/">-->
						<!--              <el-breadcrumb-item :to="{ path: '/login' }">登录</el-breadcrumb-item>-->
						<!--              <el-breadcrumb-item :to="{ path: '/register'}">注册</el-breadcrumb-item>-->
						<!--            </el-breadcrumb>-->
						你好，请{{users.id}}
						<el-button type="text" class="text_btn" @click="handleLogin">登录</el-button>
						/
						<el-button type="text" class="text_btn" @click="handleRegister">注册</el-button>
					</el-col>

					<el-col :span="6" class="text-right" v-else>
						您好，{{users.name}}
						<el-button type="text" class="text_btn" @click="logout2">退出</el-button>
					</el-col>



					<!--          <el-col :span="6" class="text-right" v-if="!user.id">
        
            你好，请
            <el-button type="text" class="text_btn" @click="handleLogin">登录</el-button>
            /
            <el-button type="text" class="text_btn" @click="handleRegister">注册</el-button>
          </el-col>
          <el-col :span="6" class="text-right" v-else>
            您好，{{user.nickName}}  <el-button type="text" class="text_btn" @click="handleLogout">退出</el-button>
          </el-col> -->


				</el-row>

			</div>
			<div class="header">
				<div class="position text-center" @click="goHome">
					<el-select v-model="city" size="mini" style="width: 100px; margin-right: 300px; margin-top: 30px;">
						<el-option label="山东" value="1"></el-option>
					</el-select>
				</div>
			</div>
		</el-header>




		<el-main :style="{'backgroundImage':'url('+backgroundImage+')'}" v-if="backgroundImage">
			<div class="main" style="overflow:none;!important">
				<slot></slot>
			</div>
		</el-main>
		<el-main v-else>
			<div class="main" style="overflow:none;!important">
				<slot></slot>
			</div>
		</el-main>


		<el-footer height="180px">
			<div class="footer">
				<el-row>
					<el-col :span="16">
						<div class="message">
							<p>中文实名：北京卫命科技有限公司 </p>
							<p>&copy; 2003-2023 版权所有 </p>
							<p>备案号：  京ICP备2021016959号-1</p>
							<p>公安备案号   11011502005634</p>
							<p>公司名称：北京卫命科技有限公司 公司地址：北京市北京经济技术开发区经海四路25号</p>
							<p>邮编：100000 客服电话：18210791139</p>
						</div>
					</el-col>
					<el-col :span="4" class="app-logo">
						<el-image :src="src" fit="fill"></el-image>
					</el-col>
					<el-col :span="4" class="app-logo">
						<el-image :src="src" fit="fill"></el-image>
					</el-col>
				</el-row>
			</div>
		</el-footer>
	</el-container>
</template>

<script>
	import $ from "jquery"


	import MButton from "../components/MButton";
	import {
		getSessionUser,
		clearSessionUser
	} from "../utils/Storage";
	import {
		logout
	} from "../api/LoginApi";

	export default {
		name: "SiteLayout",
		components: {
			MButton
		},
		props: {
			top: Boolean,
			backgroundImage: {
				required: false,
				type: String
			}
		},
		computed: {
			user() {
				return getSessionUser()
			}
		},
		data() {
			return {
				src: require("../assets/images/app-holder.png"),
				city: '山东',
				collect: false,
				users: {
					'id': 0,
					'name': ""
				}
			}
		},
		created() {
			//this.users.id = sessionStorage.setItem("uid");
			this.users.id = sessionStorage.getItem("uid");
			this.users.name = sessionStorage.getItem("uname");
		},
		methods: {


			goHome() {
				this.$router.push({
					name: '网站首页'
				})
			},

			logout2() {
				sessionStorage.removeItem("uid");
				sessionStorage.removeItem("uname");
				this.$router.push({
					name: '登录页面'
				})
			},
			handleLogin() {
				this.$router.push({
					name: '登录页面'
				})
			},
			handleRegister() {
				this.$router.push({
					name: '注册账号'
				})
			},
			handleLogout() {
				logout().then(res => {
					clearSessionUser();
					window.location.reload(true)
				})
			}
		}
	}
</script>

<style scoped>
	.el-main {
		overflow: none !important;
	}

	.el-container {
		min-height: 100%;
		margin: 0 0.1%;
	}

	.el-button--text {
		color: black;
	}

	.el-button:focus,
	.el-button:hover {
		color: black;
	}

	/deep/ .el-icon-star-on:before {
		color: #F6B85B !important;
	}

	.el-header,
	.el-footer {
		margin: 0px;
		padding: 0px;
		/* height: 108px!important; */
	}

	.text_btn {
		color: red !important;
	}

	.el-button+.el-button {
		margin-left: 0;
	}

	.top {
		height: 30px;
	}

	.top * {
		color: #000;
	}

	.top>div {
		padding: 0px 50px;
		line-height: 30px;
	}

	.header {
		height: 80px;
		/*   height: 100px; */
		background-color: #ffffff;
		margin: auto;
	}

	.header .position {
		background-image: url("../assets/images/bg-header.png");
		background-size: 100% 100%;
		height: 100%;
	}

	.footer {
		height: 100%;
		background: #000;
	}

	.main {
		width: 100%;
		margin: auto;
		min-height: 600px;
	}

	.header>div,
	.footer>div,
	.top>div {
		width: 1200px;
		margin: auto;
		color: #ffffff;
		font-size: 12px;
	}


	.footer .message {
		margin: 25px auto;

	}

	.footer .el-image {
		height: 150px;
		width: 150px;
		margin-top: 20px
	}

	.el-main {
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.app-logo {
		text-align: right;
	}
</style>
