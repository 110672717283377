/**
 * 获取图片验证码
 * @returns {string}
 */
import axios from "axios";

/**
 * 获取图片验证码的路径
 * @returns {string}
 */
export const getCaptha = () => process.env.VUE_APP_API_PATH + "/run/login/getCaptcha"

/**
 * 登录的功能接口
 * @param param
 * @returns {Promise<AxiosResponse<T>>}
 */
export const checkLogin = (param) => axios.post(process.env.VUE_APP_API_PATH+"/run/login/checkLogin", param);

/**
 * 账户注册的功能接口
 * @param param
 * @returns {Promise<AxiosResponse<T>>}
 */
//export const saveRegister = (param) => axios.post("/run/login/register", param);

export const saveRegister = (param) => axios.post( "http://doctor.com/api/login/register2", param);


/**
 * 获取默认的注册信息明细
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getRegister = () => axios.post("/run/login/getRegister");

/**
 * 退出登录的操作接口
 * @returns {Promise<AxiosResponse<T>>}
 */
export const logout = () => axios.post("/run/login/logout")

/**
 * 获取业务角色列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getRoleList = () => axios.post("/run/login/getRoleList");

/**
 * 返回注册的上一步流程
 * @param param
 * @returns {Promise<AxiosResponse<T>>}
 */
export const goBack = (param) => axios.post("/run/login/previous", param);


/**
 * 发送短信验证码到手机上
 * @param telphone
 * @returns {Promise<AxiosResponse<T>>}
 */
export const sendSMSValifitaionCode = (telphone) => axios.post("/common/sendSMSVarificationCode", {tel: telphone})
