/**
 * 设置token信息， 保存到浏览器本地
 * @param token
 */
import {getRegister} from "../api/LoginApi";

export const setToken = (token) => {
  sessionStorage.setItem("_SESSION_TOKEN", token);
}

/**
 * 获取本地存储的token信息
 * @returns {string}
 */
export const getToken = () => sessionStorage.getItem("_SESSION_TOKEN");


/**
 * 缓存登录用户信息
 * @param data
 */
export const setSessionUser = (data) => { 
  let sessionUser = getSessionUser();
  if (sessionUser && sessionUser.id){
    let oldStep = getSessionUser().step;
    let newStep = data.step;
    if (oldStep !== newStep){
      // 当SessionUser的Step发生变化时，及时请求数据并变更基础的表单form数据信息
      getRegister().then(res => {
        sessionStorage.setItem("_REGISTER", JSON.stringify(res));
      })
    }
    let dataStr = JSON.stringify(data);
    sessionStorage.setItem("_SESSION_USER", dataStr);
  } else {
    let dataStr = JSON.stringify(data);
    sessionStorage.setItem("_SESSION_USER", dataStr);
    if (data.status !== 1){
      getRegister().then(res => {
        sessionStorage.setItem("_REGISTER", JSON.stringify(res));
      })
    }
  }
}

/**
 * 获取登录存储的用户信息
 * @returns {any}
 */
export const getSessionUser = () => {
  let dataStr = sessionStorage.getItem("_SESSION_USER");
  return JSON.parse(dataStr || '{}')
}

/**
 * 获取当前注册的数据信息
 * @returns {any}
 */
export const getSessionRegister = () => {
  let dataStr = sessionStorage.getItem("_REGISTER");
  return JSON.parse(dataStr || "{}")
}

/**
 * 请除登录信息
 */
export const clearSessionUser = () => {
  sessionStorage.removeItem("_SESSION_USER");
  sessionStorage.removeItem("_SESSION_TOKEN");
  sessionStorage.removeItem("_REGISTER");
}
