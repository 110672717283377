import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menus: [
      {url: '/mine/mall', name: '礼品商城', icon: 'el-icon-s-shop'},
      {url: '/mine/service', name: '医疗服务', icon: 'el-icon-first-aid-kit'},
      // {url: '/mine/caseTemplate', name: '医疗服务_模板', icon: 'el-icon-first-aid-kit'},
      {url: '/mine/order', name: '我的预约订单', icon: 'el-icon-s-order'},
      {url: '/mine/consult', name: '我的咨询', icon: 'el-icon-s-comment'},
      {url: '/mine/message', name: '病例管理', icon: 'el-icon-s-claim'},
      {url: '/mine/gift', name: '我的礼物', icon: 'el-icon-present'},
      {url: '/mine/looks', name: '我看过', icon: 'el-icon-present'},
      {url: '/mine/wallet', name: '我的账户', icon: 'el-icon-s-finance'},
      {url: '/mine/collect', name: '我的收藏', icon: 'el-icon-star-on'},
      {url: '/mine/commentTo', name: '对我的评价', icon: 'el-icon-s-opportunity'},
      {url: '/mine/myComment', name: '我的评价', icon: 'el-icon-chat-line-square'},
      
      {url: '/mine/myUpdate', name: '我的上传', icon: 'el-icon-s-ticket'},
	  
	  
	  {url: '/mine/coupon', name: '爱心优惠券', icon: 'el-icon-s-ticket'},
      // {url: '', name: '更改手机号码', icon: 'el-icon-phone'},
      // {url: '', name: '修改密码', icon: 'el-icon-unlock'},
      // {url: '', name: '注销账号', icon: 'el-icon-s-release'},
      {url: '/mine/usage', name: '网站使用', icon: 'el-icon-s-claim'},
      {url: '/mine/recruitment', name: '求职', icon: 'el-icon-s-custom'},
      {url: '/mine/rank', name: '排名提前', icon: 'el-icon-s-marketing'},
      {url: '/mine/feedback', name: '意见建议', icon: 'el-icon-s-order'},
      {url: '/mine/copyright', name: '版权声明'},
      {url: '/mine/article', name: '我的文章及经验观点'},
    ],

    types:[
      '内科', '外科', '妇产科', '儿科', '五官科', '中医科', '海外专家'
    ]
  },
  mutations: {},
  actions: {},
  modules: {}
})
